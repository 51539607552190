import * as React from "react";
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import Hero from "../components/Hero";

const hours = [
  {
    day: "Monday",
    hours: "Closed",
  },
  {
    day: "Tuesday",
    hours: "10AM - 4PM",
  },
  {
    day: "Wednesday",
    hours: "10AM - 4PM",
  },
  {
    day: "Thursday",
    hours: "10AM - 4PM",
  },
  {
    day: "Friday",
    hours: "10AM - 4PM",
  },
  {
    day: "Saturday",
    hours: "10AM - 2PM",
  },
  {
    day: "Sunday",
    hours: "Closed",
  },
];

const IndexPage = () => (
  <Layout>
    <Hero>
      <div className="px-4 text-center">
        <h2 className="font-light text-3xl mt-32 sm:mt-0 tracking-widest sm:text-5xl small-caps text-gray-300">
          Business Hours
        </h2>
        <div className="max-w-4xl border-b sm:mt-24 text-gray-400 pb-8 pt-24 sm:pt-16 border-gray-100 space-y-4 w-full">
          {hours.map(({ day, hours }) => (
            <div className="text-lg sm:text-3xl font-light sm:mx-14 flex justify-between items-center">
              <p
                className={
                  hours === "Closed" ? "text-gray-500" : "text-gray-400"
                }
              >
                {day}
              </p>
              <p>{hours}</p>
            </div>
          ))}
        </div>
        <div>
          <h2 className="font-light text-center text-3xl sm:text-5xl small-caps my-8 text-gray-300">
            Cancellation Policy
          </h2>
          <p className="text-gray-400 text-2xl sm:text-3xl font-light small-caps max-w-4xl text-center">
            I charge a 50% cancellation fee for any cancellations the day before
            the appointment and a 100% cancellation/no-show fee on the day of
            the appointment
          </p>
        </div>
      </div>
    </Hero>
  </Layout>
);

export const Head = () => <Seo title="About" />;

export default IndexPage;
