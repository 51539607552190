import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Hero = ({ children }) => {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      file(relativePath: { eq: "background.jpg" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="relative">
      <div
        className="hero flex flex-col items-center"
        style={{ backgroundImage: `url("${data.file.publicURL}")` }}
      ></div>
      <div className="flex hero-content flex-col relative z-10 items-center">
        <h1 className="sm:block text-4xl small-caps sm:text-7xl text-center py-32">
          Fionna's house of hair
        </h1>
        <h1
          style={{ top: "150px" }}
          className="absolute sm:hidden bg-black bg-opacity-75 text-4xl small-caps sm:text-7xl text-center my-32 px-4"
        >
          Fionna's house of hair
        </h1>
        {children}
      </div>
    </div>
  );
};

export default Hero;
